import { AuthenticatedRoute } from './components/AuthenticatedRoute'
import Loading from './components/LoadingSpinner'

import { AuthContextProvider } from './context/AuthContext'

import './App.css'
import { captureSentryMessage, lazyWithRetry } from './utility'
import { ROUTES } from './utility/constants'
import { useEffect, useState, Suspense } from 'react'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'
import { Routes, Route, useLocation } from 'react-router-dom'

const CalendarPage = lazyWithRetry(() => import('./pages/Calendar'))
const AdminCustomizeStorePage = lazyWithRetry(() => import('./pages/Store/AdminCustomizeStore'))
const ContactUsPage = lazyWithRetry(() => import('./pages/ContactUs'))
const HowToEarn = lazyWithRetry(() => import('./pages/HowToEarn'))
const LandingPage = lazyWithRetry(() => import('./pages/LandingPage'))
const LoginPage = lazyWithRetry(() => import('./pages/Login'))
const CleverLoginPage = lazyWithRetry(() => import('./pages/Login/Clever'))
const NotFound = lazyWithRetry(() => import('./pages/NotFound'))
const OrdersPage = lazyWithRetry(() => import('./pages/Orders'))
const ProfilePage = lazyWithRetry(() => import('./pages/Profile'))
const Status = lazyWithRetry(() => import('./pages/Status'))
const StorePage = lazyWithRetry(() => import('./pages/Store'))
const StudentActivities = lazyWithRetry(() => import('./pages/StudentActivities'))
const StudentDashboardPage = lazyWithRetry(() => import('./pages/StudentDashboard'))
const TeacherClassPage = lazyWithRetry(() => import('./pages/TeacherClass'))
const TeacherClassesPage = lazyWithRetry(() => import('./pages/TeacherClasses'))
const TeacherReportsPage = lazyWithRetry(() => import('./pages/Reports'))
const TeacherRostersPage = lazyWithRetry(() => import('./pages/TeacherRosters'))
const AdminCustomizeActionsPage = lazyWithRetry(() => import('./pages/AdminActions/Customize'))
const AdminActionsPage = lazyWithRetry(() => import('./pages/AdminActions'))
const PayPeriodBreakdownPage = lazyWithRetry(() => import('./pages/PayPeriodBreakdown'))

const gaOptions: { userId?: string } = {}

if (localStorage.getItem('biziUserId')) {
  gaOptions.userId = localStorage.getItem('biziUserId') as string
}

// Initialize Google Analytics
ReactGA.initialize('G-JQYTSDRM8X', {
  testMode: !window.location.origin.includes('app.bizimotivates.com'),
  gaOptions,
})

function App() {
  const location = useLocation()
  const [title, setTitle] = useState('Bizi App')

  useEffect(() => {
    const path = window.location.pathname ?? ''
    const routeConfigs = Object.values(ROUTES)
    const routeConfig = routeConfigs.find((routeConfig) => {
      const routePath = routeConfig.path ?? ''
      return path.startsWith(routePath)
    })
    if (routeConfig) {
      setTitle(routeConfig.title)
    } else {
      setTitle('Bizi App')
      captureSentryMessage(`Route not found path: ${path}`)
    }
  }, [location])

  return (
    <AuthContextProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="title" content={title} data-react-helmet="true"></meta>
        <title>{title}</title>
      </Helmet>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={ROUTES.LANDING.path} element={<LandingPage />} key={ROUTES.LANDING.key} />
          <Route
            path={ROUTES.CUSTOM_LOGIN.path}
            element={<LoginPage />}
            key={ROUTES.CUSTOM_LOGIN.key}
          />
          <Route path={ROUTES.LOGIN.path} element={<CleverLoginPage />} key={ROUTES.LOGIN.key} />
          <Route path={ROUTES.STATUS.path} element={<Status />} key={ROUTES.STATUS.key} />
          <Route
            path={ROUTES.HOW_TO_EARN.path}
            element={
              <AuthenticatedRoute>
                <HowToEarn />
              </AuthenticatedRoute>
            }
            key={ROUTES.HOW_TO_EARN.key}
          />
          <Route
            path={ROUTES.STORE.path}
            element={
              <AuthenticatedRoute>
                <StorePage />
              </AuthenticatedRoute>
            }
            key={ROUTES.STORE.key}
          />
          <Route
            path={ROUTES.CUSTOMIZE_STORE.path}
            element={
              <AuthenticatedRoute>
                <AdminCustomizeStorePage />
              </AuthenticatedRoute>
            }
            key={ROUTES.CUSTOMIZE_STORE.key}
          />
          <Route
            path={ROUTES.ORDERS.path}
            element={
              <AuthenticatedRoute>
                <OrdersPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.ORDERS.key}
          />
          <Route
            path={ROUTES.CLASSES.path}
            element={
              <AuthenticatedRoute>
                <TeacherClassesPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.CLASSES.key}
          />
          <Route
            path="/class/:internalPeriodId"
            element={
              <AuthenticatedRoute>
                <TeacherClassPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.CLASS.key}
          />
          <Route
            path={ROUTES.ROSTERS.path}
            element={
              <AuthenticatedRoute>
                <TeacherRostersPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.ROSTERS.key}
          />
          <Route
            path={ROUTES.REPORTS.path}
            element={
              <AuthenticatedRoute>
                <TeacherReportsPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.REPORTS.key}
          />
          <Route
            path={ROUTES.DASHBOARD.path}
            element={
              <AuthenticatedRoute>
                <StudentDashboardPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.DASHBOARD.key}
          />
          <Route
            path={ROUTES.ACTIVITIES.path}
            element={
              <AuthenticatedRoute>
                <StudentActivities />
              </AuthenticatedRoute>
            }
            key={ROUTES.ACTIVITIES.key}
          />
          <Route
            path={ROUTES.PROFILE.path}
            element={
              <AuthenticatedRoute>
                <ProfilePage />
              </AuthenticatedRoute>
            }
            key={ROUTES.PROFILE.key}
          />
          <Route
            path={ROUTES.CUSTOMIZE_BIZI_BONUSES_FLAGS.path}
            element={
              <AuthenticatedRoute>
                <AdminCustomizeActionsPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.CUSTOMIZE_BIZI_BONUSES_FLAGS.key}
          />
          <Route
            path={ROUTES.ADMIN_BIZI_BONUSES_FLAGS.path}
            element={
              <AuthenticatedRoute>
                <AdminActionsPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.ADMIN_BIZI_BONUSES_FLAGS.key}
          />
          <Route
            path={ROUTES.CALENDAR.path}
            element={
              <AuthenticatedRoute>
                <CalendarPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.CALENDAR.key}
          />
          <Route
            path={ROUTES.PAY_PERIOD_BREAKDOWN.path}
            element={
              <AuthenticatedRoute>
                <PayPeriodBreakdownPage />
              </AuthenticatedRoute>
            }
            key={ROUTES.PAY_PERIOD_BREAKDOWN.key}
          />
          <Route path={ROUTES.CONTACT.path} element={<ContactUsPage />} key={ROUTES.CONTACT.key} />
          <Route path="*" element={<NotFound />} key="NotFoundPage" />
        </Routes>
      </Suspense>
    </AuthContextProvider>
  )
}

export default App
